<template>
<v-card flat>
  <v-card-text class="fill-height pa-0 ma-0" >
    <v-toolbar class="transparent" flat>
      <v-spacer></v-spacer>
      <v-tooltip bottom color="shaza_yellow">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field 
            v-bind="attrs" v-on="on"
            dense
            @keydown.enter.prevent="searchTitle"
            :loading="searchLoading"
            :readonly="searchLoading"
            color="shaza_yellow"
            v-model="searchString"
            :label="inputLang.titleSearch.label"
            :error-messages="searchErrors"
            outlined
            @keydown="searchErrors = []"
            :disabled="searchLoading"
            @click:append="searchTitle"
            append-icon="mdi-magnify"
          ></v-text-field>
        </template>
        <span>{{buttonLang.searchLabel.tooltip}}</span>
      </v-tooltip>
      <v-spacer></v-spacer>
    </v-toolbar >
    <v-row no-gutters v-show="showResults">
      <v-sheet class="mx-auto" max-width="1200" style="width:100%">
        <v-slide-group :show-arrows="true" ref="slideGroup">
          <v-slide-item v-for="(item, i ) in searchResults" :key="`slider_item_${i}`" >
            <v-card @click="selectResult(item)" flat class="ma-2 title_card"> 
              <v-scale-transition>
                <v-img height="150" :src="item.Poster" class="align-end card_image" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
                  <ul>
                    <li class="shaza_yellow caption">{{item.Type}}</li>
                    <li class="shaza_yellow caption">{{item.Year}}</li>
                  </ul>
                  <div class="card_title caption pl-2">{{item.Title}}</div>
                </v-img>
                </v-scale-transition>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </v-row>
  </v-card-text>
</v-card>
</template>

<script>
  export default {
    data(){
      return {
        searchErrors: [],
        searchString: '',
        searchLoading: false,
        searchResults: []
      }
    },
    computed:{
      showResults(){
        return (this.searchResults.length && this.searchResults.length != 0);
      }
    },
    methods: {
      async selectResult(res){
        let self = this;
        self.showBufferOverlay({
          msg: `Setting ${res.Title}...`,
        })
        let titleData = await self.getTitleInformation(res.imdbID);
        self.$emit('selected', titleData);
        self.hideBufferOverlay();
      },
      getTitleInformation(id){
        let self = this;
        return new Promise((resolve, reject)=>{
          fetch(`https://${self.appConfig.rapidAPiHost}/?i=${id}&r=json`, {
            "method": "GET",
            "headers": {
              "x-rapidapi-host": self.appConfig.rapidAPiHost,
              "x-rapidapi-key": self.appConfig.rapidAPiKey
            }
          })
          .then(async res => {
            let json = await res.json();
            if(json.Response == 'True'){
              console.log(json);
              resolve(json)
            }else{
              reject(json.Error)
            }
          })
          .catch(err => {
            reject(err)
          });
        })
      },
      searchTitle(){
        let self = this;
        if(!self.searchString){
          return false;
        }

        self.showBufferOverlay({
          msg: `Searching IMDB for ${self.searchString}...`,
        })

        self.searchErrors = [];
        self.searchLoading = true;
        let query = encodeURIComponent(`${self.searchString}`)
        fetch(`https://${self.appConfig.rapidAPiHost}/?s=${query}&page=1&r=json`, {
          method: "GET",
          headers: {
            "x-rapidapi-host": self.appConfig.rapidAPiHost,
            "x-rapidapi-key": self.appConfig.rapidAPiKey
          }
        })
        .then(async response => {
          let res = await response.json()
          console.log('RESSS', res);
          if(res.Response === "True"){
            self.searchResults = res.Search
          }
          else {
            self.searchErrors.push(res.Error);
          }
          self.searchLoading = false;
          self.hideBufferOverlay();
        })
        .catch(err => {
          self.searchErrors.push(err.Error);
          console.error(err);
          self.searchLoading = false;
          self.hideBufferOverlay();
        });
      }
    },
  }
</script>

<style lang="scss" scoped>
.title_card{
  .card_img{
    position:relative
  }
  .card_title{
    background-color: rgba(0,0,0,0.8);
    color: white;
  }
  ul{
    padding: 0;
    list-style: none;
    position:absolute; 
    top:5px; 
    left:5px;
    li{
      text-align:center;
      padding:0px 5px;
      margin-top:2px;
      margin-left: 0;
      display: block;
    }
  }
}
</style>