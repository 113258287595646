<template>
<div v-if="!loadingEditor">
  <div v-if="!itemFound">
    <v-alert dense dark border="left" type="warning" icon="mdi-alert-circle-outline">The selectd title could not be found. This might be because it was deleted from the system.</v-alert>
  </div>
  <div v-else>
    <content-heading text="title configuration"/>
    
    <v-card flat transparent v-if="!loadingEditor">
      <v-card-text class="ma-0 pa-0">
        <v-form v-model="titleValid" tag="v-row" ref="editorForm">
          <v-tabs v-if="editorState === 'create'" v-model="typeTab" background-color="transparent" color="shaza_dark_blue" center-active fixed-tabs show-arrows>
            <v-tab v-for="(type, i) in titleTypes" :class="{'shaza_light_blue--text' : i !== typeTab}" :key="`${type.type}`" >{{type.label}}</v-tab>
          </v-tabs>
          <v-input v-model="titleData.type" :rules="validators.titleType()"/>
          <imdb-search v-if="editorState === 'create' && !!typeUiCfg.showIMDB" @selected="selectSearchedTitle"/>
          <v-row  no-gutters justify="end" align="center" v-if="showInput('publicEnabled')">
            <span>{{inputLang.TitlePublicEnabled.label}}: </span>
            <v-btn-toggle dense id="publicEnabledToggle" v-model="publicEnabled" mandatory :label="inputLang.TitlePublicEnabled.label" :color="publicEnabled?'green':'red'" :aria-label="publicEnabled?'Publicly enabled, toggle to disable.':'Disabled, toggle to enable publicly.' "> 
              <v-btn :value="false" aria-label="Disable title"> 
                <v-icon :color="publicEnabled?'':'error'">close</v-icon> 
              </v-btn>
              <v-btn :value="true" aria-label="Enable title"> 
                <v-icon :color="publicEnabled?'green':''">check</v-icon> 
              </v-btn> 
            </v-btn-toggle>
          </v-row>
          <v-row no-gutters >
            <v-col cols="12" md="3"  align="center" justify="center">
              <v-row v-if="showInput('poster')" class="mx-4">
                <v-card v-if="posterUploader.isUrl" flat class="pa-2 transparent" style="width:100%" min-width="200">
                  <v-card-actions class="pa-0 ma-0">
                      <v-btn aria-label="Remove poster image" icon text color="error" class="pa-0 ma-0"
                      style="position:absolute; top:-14px; right:-14px;"
                        @click="posterUploader.source = null; posterUploader.isUrl = false; posterUploader.isUpdate = true; "
                      >
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </v-card-actions>
                  <v-card-text class="pa-0 ma-0 ">
                    <img :src="posterUploader.source" style="width:100%" />
                    <v-progress-linear
                      v-show="posterUploader.loading"
                      background-color="shaza_light_blue"
                      color="success"
                      :value="posterUploader.progress"
                      height="12"
                    >
                      <span class="caption white--text">{{posterUploader.progress}}%</span>
                    </v-progress-linear>
                  </v-card-text>
                </v-card>

                <v-card v-else flat class="pa-2 transparent poster_upload" style="width:100%">
                  <v-card-actions class="pa-0 ma-0">
                      <v-menu open-on-hover offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="shaza_light_blue" dark v-bind="attrs" v-on="on" style="position:absolute; top:-14px; left:-14px;">mdi-information</v-icon>
                        </template>
                        <v-card color="shaza_dark_blue" dark>
                          <v-card-text>
                            Please only use: {{ uiDataSets.mimeTypeExtensionMapping.images.map(o=> `(${o.extension})`).join(', ') }} files
                          </v-card-text>
                        </v-card>
                      </v-menu>
                  </v-card-actions>
                  <v-card-text class="pa-0 ma-0 ">
                      <v-file-input
                        :rules="validators.poster( inputRequired('poster'), uiDataSets.mimeTypeExtensionMapping.images.map(o=> o.mimeType.trim()) )"
                        :accept="uiDataSets.mimeTypeExtensionMapping.images.map(o=> o.extension).join(', ')"
                        placeholder="Upload Title Poster"
                        prepend-inner-icon="mdi-image-plus"
                        append-icon="mdi-image-plus"
                        prepend-icon=""
                        :clearable="false"
                        filled
                        height="200"
                        color="shaza_yellow"
                        outlined
                        flat
                        v-model="posterUploader.source"
                        @change="blobToBase64(posterUploader, true)"
                      />
                  </v-card-text>
                </v-card>
              </v-row>
              <v-row v-if="showInput('audio')" class="mx-4">
                <v-row no-gutters justify="center" v-if="titleData.fingerprinting_progress === 'required' || titleData.fingerprinting_progress === 'started'">
                  Audio proccessing in progress
                  <v-progress-circular indeterminate size="20" width="2" color="shaza_light_blue"/>
                </v-row>
                <v-row v-else no-gutters>
                  <v-card v-if="audioUploader.isUrl" flat class="pa-2 transparent" style="width:100%" min-width="200">
                    <v-card-actions class="pa-0 ma-0" v-if="editorState === 'create'">
                        <v-btn icon text color="error" class="pa-0 ma-0"
                        aria-label="Remove audio track"
                          style="position:absolute; top:-14px; right:-14px;"
                          @click="audioUploader.source = null; audioUploader.isUrl = false; audioUploader.isUpdate = true; "
                        >
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </v-card-actions>
                    <v-card-text class="pa-0 ma-0 ">
                      <audio controls v-if="audioUploader.isUrl" style="width:100%; margin:auto;">
                        <source :src="audioUploader.source">
                      </audio>
                      <v-progress-linear
                        v-show="audioUploader.loading"
                        background-color="shaza_light_blue"
                        color="success"
                        :value="audioUploader.progress"
                        height="12"
                      >
                        <span class="caption white--text">{{audioUploader.progress}}%</span>
                      </v-progress-linear>
                    </v-card-text>
                  </v-card>

                  <v-card v-else flat class="pa-2 transparent audio_upload" style="width:100%"> 
                    <v-card-actions class="pa-0 ma-0">
                        <v-menu open-on-hover offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="shaza_light_blue" dark v-bind="attrs" v-on="on" style="position:absolute; top:-14px; left:-14px;">mdi-information</v-icon>
                          </template>
                          <v-card color="shaza_dark_blue" dark>
                            <v-card-text>
                              Please use an (.mp3) file, 
                              <br />
                              Sample Rate: 48000Hz, 
                              <br />
                              Bits per sample: 32,  
                              <br />
                              Bitrate: 320kb/s
                            </v-card-text>
                          </v-card>
                        </v-menu>
                    </v-card-actions>
                    <v-card-text class="pa-0 ma-0 ">
                        <v-file-input
                          :rules="validators.audio( inputRequired('audio'), uiDataSets.mimeTypeExtensionMapping.audio.map(o=> o.mimeType) )"
                          :accept="uiDataSets.mimeTypeExtensionMapping.audio.map(o=> o.extension).join(', ')"
                          placeholder="Upload Title Audio"
                          prepend-inner-icon="mdi-music-note-plus"
                          append-icon="mdi-music-note-plus"
                          prepend-icon=""
                          :clearable="false"
                          filled
                          height="60"
                          color="shaza_yellow"
                          outlined
                          flat
                          v-model="audioUploader.source"
                          @change="blobToBase64(audioUploader, false)"
                        />
                    </v-card-text>
                  </v-card>
                </v-row>
              </v-row>
            </v-col>
            <v-col cols="12" md="9">
              <v-row>
                <v-col cols="12" md="6" v-if="showInput('subTitleBook')">
                  <v-autocomplete
                    v-model="titleData.parentId"
                    :rules="validators.subTitleBook(inputRequired('subTitleBook'))"
                    :items="subTitleBooks"
                    :label="inputLang.subTitleBook.label"
                    item-value="titleId"
                    item-text="title"
                    dense
                  ></v-autocomplete>
                </v-col >
                <v-col cols="12" md="6" v-if="showInput('subTitleSeries')">
                  <v-autocomplete
                    v-model="titleData.parentId"
                    :items="subTitleSeries"
                    :rules="validators.subTitleSeries(inputRequired('subTitleSeries'))"
                    :label="inputLang.subTitleSeries.label"
                    item-value="titleId"
                    item-text="title"
                    dense
                  ></v-autocomplete>
                </v-col >
                <v-col cols="12" md="6" v-if="showInput('episode')">
                  <v-text-field
                    dense
                    type="number"
                    :min="1"
                    v-model.number="titleData.episode"
                    :rules="validators.episode(inputRequired('episode'))"
                    :label="inputLang.episode.label"
                  />
                </v-col >
                <v-col cols="12" md="6" v-if="showInput('season')">
                  <v-text-field
                    dense
                    type="number"
                    :min="1"
                    v-model.number="titleData.season"
                    :rules="validators.season(inputRequired('season'))"
                    :label="inputLang.season.label"
                  />
                </v-col>
                <v-col cols="12" md="6" v-if="showInput('chapter')">
                  <v-text-field
                    dense
                    type="number"
                    :min="1"
                    v-model.number="titleData.chapter"
                    :rules="validators.chapter(inputRequired('chapter'))"
                    :label="inputLang.chapter.label"
                  />
                </v-col>
                <v-col cols="12" v-if="showInput('title')">
                  <v-text-field
                    dense
                    v-model.trim="titleData.title"
                    :rules="validators.title(inputRequired('title'))"
                    :label="inputLang[showInput('chapter')?'chapterTitle':'title'].label"
                  />
                </v-col>
                <v-col cols="12" v-if="showInput('directors')" >
                  <v-combobox
                    v-model="titleData.directors"
                    :rules="validators.directors(inputRequired('directors'))"
                    :label="inputLang.directors.label"
                    :items="directors"
                    multiple
                    hide-selected
                    small-chips
                    dense
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        class="mt-1"
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        @click="data.select"
                        @click:close="removeChip(data.item, 'directors')"
                        dark
                        color="shaza_light_blue"
                        label small
                        close
                      >{{data.item}}</v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12" v-if="showInput('writers')">
                  <v-combobox
                    v-model="titleData.writers"
                    :rules="validators.writers(inputRequired('writers'))"
                    :label="inputLang[showInput('subTitleBook')?'bookWriters':'writers'].label"
                    :items="writers"
                    multiple
                    hide-selected
                    small-chips
                    dense
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        class="mt-1"
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        @click="data.select"
                        @click:close="removeChip(data.item, 'writers')"
                        dark
                        color="shaza_light_blue"
                        label small
                        close
                      >{{data.item}}</v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12" v-if="showInput('actors')">
                  <v-combobox
                    v-model="titleData.actors"
                    :rules="validators.actors(inputRequired('actors'))"
                    :label="inputLang[showInput('subTitleBook')?'bookActors':showInput('chapter')?'chapterActors':'actors'].label"
                    :items="actors"
                    multiple
                    hide-selected
                    small-chips
                    dense
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        class="mt-1"
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        @click="data.select"
                        @click:close="removeChip(data.item, 'actors')"
                        dark
                        color="shaza_light_blue"
                        label small
                        close
                      >{{data.item}}</v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12" md="6" class="pt-0" v-if="showInput('runtimeMinutes')">
                  <div class="caption" style="margin-left: 10px;">{{inputLang.runtimeMinutes.label}}</div>
                  <v-slider
                    hide-details
                    v-model="titleData.runtimeMinutes"
                    :rules="validators.runtimeMinutes(inputRequired('runtimeMinutes'))"
                    thumb-color="shaza_yellow"
                    color="shaza_yellow"
                    thumb-label="always"
                    append-icon="mdi-clock-time-two-outline"
                    max="600"
                    min="1"
                    height="60px"
                  >
                  <template v-slot:append>
                    <v-text-field
                      dense
                      v-model.number="titleData.runtimeMinutes"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 60px"
                      max="600"
                      min="1"
                    ></v-text-field>
                  </template>
                </v-slider>
                </v-col>
                <v-col cols="12" md="6" v-if="showInput('released')">
                  <v-menu
                      ref="releaseDateMenu"
                      v-model="releaseDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          v-model="titleData.released"
                          :rules="validators.released(inputRequired('released'))"
                          :label="inputLang.released.label"
                          append-outer-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker ref="releaseDatePicker" v-model="titleData.released" @input="releaseDateMenu = false; setTitleYear(titleData.released)" no-title scrollable >
                      </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="6" v-if="showInput('rated')">
                  <v-text-field
                      dense
                      v-model.number="titleData.rated"
                      :rules="validators.rated(inputRequired('rated'))"
                      :label="inputLang.rated.label"
                    />
                </v-col>
                <v-col cols="12" md="6" v-if="showInput('previewUrl')">
                  <v-text-field
                    type="url"
                    placeholder="https://"
                    dense
                    v-model.trim="titleData.previewUrl"
                    :rules="validators.previewUrl(inputRequired('previewUrl'))"
                    :label="inputLang.previewUrl.label"
                  />
                </v-col>
                <v-col cols="12" v-if="showInput('wheretowatch')" >
                  <v-combobox
                    v-model="titleData.wheretowatch"
                    :label="inputLang.wheretowatch.label"
                    :items="wheretowatch"
                    multiple
                    hide-selected
                    small-chips
                    dense
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        class="mt-1"
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        @click="data.select"
                        @click:close="removeChip(data.item, 'wheretowatch')"
                        dark
                        color="shaza_dark_blue"
                        label small
                        close
                      >{{data.item}}</v-chip>
                    </template>
                  </v-combobox>
                </v-col>

                <v-col cols="12" md="6" v-if="showInput('score')">
                  <v-text-field
                    type="number"
                    dense
                    v-model.number="titleData.score"
                    :rules="validators.score(inputRequired('score'))"
                    :label="inputLang.score.label"
                  />
                </v-col>
                <v-col cols="12" md="6" v-if="showInput('categories')">
                  <v-combobox
                    v-model="titleData.categories"
                    :rules="validators.categories(inputRequired('categories'))"
                    :label="inputLang.categories.label"
                    :items="categories"
                    :value="titleData.categories? titleData.categories.map(o => o.toLowerCase()) : []"
                    @input="setCategories($event)"
                    multiple
                    hide-selected
                    small-chips
                    dense
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        class="mt-1"
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        @click="data.select"
                        @click:close="removeChip(data.item, 'categories')"
                        dark
                        color="pink"
                        label small
                        close
                      >{{data.item}}</v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12" md="6" v-if="showInput('genre')">
                  <v-combobox
                    v-model="titleData.genre"
                    :rules="validators.genre(inputRequired('genre'))"
                    :label="inputLang.genre.label"
                    :items="uiDataSets.movieGenres.map(o => o.toLowerCase())"
                    :value="titleData.genre? titleData.genre.map(o => o.toLowerCase()) : []"
                    @input="setGenre($event)"
                    multiple
                    hide-selected
                    small-chips
                    dense
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        class="mt-1"
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        @click="data.select"
                        @click:close="removeChip(data.item, 'genre')"
                        dark
                        color="shaza_light_blue"
                        label small
                        close
                      >{{data.item}}</v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12" v-if="showInput('synopsis')">
                  <v-textarea
                    dense
                    outlined
                    v-model.trim="titleData.synopsis"
                    :rules="validators.synopsis(inputRequired('synopsis'))"
                    :label="inputLang.synopsis.label"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <div v-if="!!typeUiCfg.showAdTracks && editorState === 'update'">
      <content-heading text="AD Tracks Configuration"/>
      <track-editor :titleId="titleId"/>
    </div>
  </div>

  <v-footer app padless style="z-index: 10;" class="shaza_dark_blue">
    <v-col class="text-right ma-0 pa-0" cols="12">
      <v-spacer></v-spacer>
      <v-btn tabindex="0" aria-label="Back" small dark class="elevation-0" text :to="{ path:'/titleManagement' }" >
        <v-icon class="mr-3">chevron_left</v-icon>
        {{buttonLang.back.label}}
      </v-btn>
      <v-btn tabindex="0" v-if="editorState === 'update'" aria-label="Save new title" small dark class="elevation-0" text color="shaza_yellow" @click="reloadEditor('create', false)">
        add title
        <v-icon class="ml-3">add_circle</v-icon>
      </v-btn>
      <v-btn tabindex="0" small dark aria-label="Delete" class="elevation-0" text color="error" @click="deleteTitle()">
        {{buttonLang.deleteTitle.label}}
        <v-icon class="ml-3">delete_forever</v-icon>
      </v-btn>
      <v-btn tabindex="0" small aria-label="Save changes" class="elevation-0" color="shaza_yellow"  @click="saveTitle"> 
        {{ editorState? `${editorState} ${lang.titleTypes[titleData.type]}` : "loading"}}
        <v-icon class="ml-3">save</v-icon>
      </v-btn>
    </v-col>
  </v-footer>
</div>
</template>

<script>
import imdbSearch from '@/components/IMDBSearch.vue'
import TrackEditor from '@/components/titleManagement/trackEditor.vue'

import { 
  getShazacinMetadataTitle
} from '@/graphql/queries'

import { 
  updateShazacinMetadataTitles, 
  createShazacinMetadataTitles, 
  deleteShazacinMetadataTitles
} from '@/graphql/mutations'

  export default {
    components: {
      imdbSearch,
      TrackEditor
    },
    data(){
      return {
        itemFound: false,
        titleId: '',
        editorState: '',
        titles: [],
        loadingEditor: false,
        titleLoading: false,
        originalTitleData: {},
        titleData: {},
        blankTitle:{
          actors: [],
          categories: [],
          directors: [],
          fingerprinting_progress: null,
          genre: [],
          previewUrl: null,
          wheretowatch: [],
          publicEnabled: 0,
          rated: null,
          released: null,
          runtimeMinutes: 0,
          score: 0,
          synopsis: null,
          title: null,
          titleId: 0,
          type: 'movie',
          parentId: null,
          writers: [],
          year: null
        },
        titleValid: false,
        savingTitle: false,
        posterUploader: {
          isPossible: true,
          source: null,
          isUrl: false,
          isUpdate: true,
          loading: false,
          progress: 0
        },
        audioUploader:{
          isPossible: true,
          source: null,
          isUrl: false,
          isUpdate: true,
          loading: false,
          progress: 0
        },
        releaseDateMenu: false,
      }
    },
    computed:{
      lang(){
        return this.$t('pages.TrackUploader');
      },
      typeTab: {
        get(){
          let self = this;
          return self.uiDataSets.titleTypeUiConfig.find(o=> o.type === self.titleData.type)?.tabIndex || 0
        },
        set(index){
          let self = this;
          self.titleData.type = [...self.uiDataSets.titleTypeUiConfig].find(o=> o.tabIndex === index)?.type || 'movie'
          self.setAudioOrPoster();
        }
      },
      typeUiCfg(){
        let self = this;
        let cfg = self.uiDataSets.titleTypeUiConfig.find(o=> o.type === self.titleData.type );
        console.log('typeUiCfg', self.uiDataSets.titleTypeUiConfig, self.titleData.type);
        return cfg
      },
      publicEnabled:{
        get() {
          return this.titleData.publicEnabled?true:false
        },
        set(v) {
          this.titleData.publicEnabled = v==true?1:0
        }
      },
      titleTypes(){
        let self = this;
        return [...self.uiDataSets.titleTypeUiConfig].map(o=> ({ ...o,...{label: self.lang.titleTypes[o.type]} }));
      },
      subTitleBooks(){
        let self = this; 
        return self.titles.filter(o=> o.type && o.type === 'book' && !o.parentId);
      },
      subTitleSeries(){
        let self = this;
        return self.titles.filter(o=> o.type && o.type === 'series' && !o.parentId);
      },
      directors(){
        console.log('TITLES AT: directors ');
        return [...new Set(this.titles.map(o=> o.directors? o.directors.map(oo=> oo) : []).flat())];
      },
      wheretowatch(){
        console.log('TITLES AT: wheretowatch ');
        return [...new Set(this.titles.map(o=> o.wheretowatch? o.wheretowatch.map(oo=> oo) : []).flat())];
      },
      writers(){
        console.log('TITLES AT: writers ');
        return [...new Set(this.titles.map(o=> o.writers? o.writers.map(oo=> oo) : []).flat())];
      },
      categories(){
        console.log('TITLES AT: categories ');
        return [...new Set(this.titles.map(o=> o.categories? o.categories.map(oo=> oo.toLowerCase()) : []).flat())];
      },
      actors(){
        console.log('TITLES AT: actors ');
        return [...new Set(this.titles.map(o=> o.actors? o.actors.map(oo=> oo) : []).flat())];
      },
      years(){
        let oldestMovie = 1888;
        let current = new Date().getFullYear();
        let years = [current];
        for(let i = (current-oldestMovie); i >= 0; i--){
          years.push(oldestMovie+i);
        }
        return years;
      }
    },
    methods: {
      async reloadEditor(to, res){
        console.log('reloading UI...', to);
        let self = this;
        self.titles = await self.getAllTitles();
        if(to === 'update'){
          let path = `/TrackEditor/update/${res.data.titleId}`;
          if(path !== self.$route.fullPath){
            self.$router.replace({path});
          }
          self.titleId = res.data.titleId;
          let item = await self.getTitle(self.titleId);
          self.editorState = 'update';
          self.editItem(item);
        }
        
        if(to === 'create'){
          self.$router.replace({path: `/TrackEditor/create`});
          self.titleId = null;
          self.editorState = 'create';
          self.addNewTitle()
        }
      },
      setAudioOrPoster(){
        let self = this;
        self.audioUploader.isPossible = [...self.typeUiCfg.inputCfg].includes('audio');
        self.posterUploader.isPossible = [...self.typeUiCfg.inputCfg].includes('poster');
      },
      inputRequired(input){
        let self = this;
        let inpCfg = [...self.typeUiCfg.inputCfg].find(o=> o.input === input);
        return inpCfg?.required || false
      },
      showInput(input){
       
        let self = this;
        //  console.log('GETTING INPUT CFG FOR', input, self.typeUiCfg);
        return [...self.typeUiCfg.inputCfg].map(o=> o.input).includes(input);
      },
      getTitle(titleId){
        return new Promise(async (resolve, reject)=>{
          let self = this;
          const result = await self.$Amplify.API.graphql({ query: getShazacinMetadataTitle, variables: {titleId}, fetchPolicy: 'network-only' })
          const title = result.data.getShazacinMetadataTitle
          resolve(title)
        });
      },
      getBlobFromUrl(imageUrl){
        return new Promise((resolve, reject)=>{
          fetch(imageUrl)
          .then(async res =>{ 
            let blob = await res.blob(); 
            resolve(blob);  
          })
          .catch(e=>{ console.error(e); reject(e) })
        })
      },
      // async blobToDataURI_ORIGINAL_LIIMITTTTTto514MbString(blob) {
      async blobToDataURI(blob) {
        return new Promise((resolve, reject) => {
          const a = new FileReader();

          a.onerror = (e) => {
            console.error(`Error occurred with FileReader: `, e);
            reject(e);
          };

          a.onload = () => {
            console.info(`Onload function called. a.result is `, a.result);
            resolve(a.result);
          };

          a.onabort = (e) => {
            console.warn(`Load aborted: e: `, e);
            reject(e);
          };

          a.onloadend = (e) => {
            console.info(`Loading ended: `, e);
          };

          console.warn(`Before reading blob as Data URL, blob is: `, blob);
          
          if (blob) {
            a.readAsDataURL(blob);
          } else {
            console.error(`No blob passed in`);
            reject(new Error('No blob passed in'));
          }
        });

      },
      async blobToDataURI_NEWCHUNKY(blob) {
        return new Promise(async (resolve, reject) => {
          const fr = new FileReader();

          fr.onerror = (e) => {
            console.error(`Error occurred with FileReader: `, e);
            reject(e);
          };

          // Best to add 2 so it strips == from all chunks
          // except from the last chunk
          var chunkSize = (1 << 16) + 2 
          var pos = 0
          var b64chunks = []
          
          while (pos < blob.size) {
            await new Promise(rs => {
              fr.readAsDataURL(blob.slice(pos, pos + chunkSize))
              fr.onload = () => {
                const b64 = fr.result.split(',')[1]
                // Keeping it as a blob allaws browser to offload memory to disk
                b64chunks.push(new Blob([b64]))
                rs()
              }
              pos += chunkSize
            })
          }

          // How you concatinate all chunks to json is now up to you.
          // this solution/answer is more of a guideline of what you need to do
          // There are some ways to do it more automatically but here is the most
          // simpliest form
          // (fyi: this new blob won't create so much data in memory, it will only keep references points to other blobs locations)
          console.info(`We have the CHUNKS here as`, b64chunks)
          const jsonBlob = new Blob([
            '{"data": "', ...b64chunks, '"}'
          ], { type: 'application/json' })
          console.info(`We have the jsonBlob here as`, jsonBlob)
          return resolve(b64chunks)
        });

      },
      async blobToBase64_ORIG_With_textblobsizeLimitation512Mb(data){
        let self = this;
        let uri = await self.blobToDataURI(data.source);
        data.isUrl = true;
        data.isUpdate = true;
        data.source = uri;
      },
      async blobToBase64(data, isImage){
        let self = this;
        if (isImage) {
          let uri = await self.blobToDataURI(data.source);
          data.source = uri;
        }
        else {
          data.source = URL.createObjectURL(data.source)
          // self.audioUploader.source = URL.createObjectURL(data.source); //! Ensure blob is loaded so playback can happen
        }
        // console.info(`After trying to blobToDataURI, we have uri as: `, uri)
        data.isUrl = true;
        data.isUpdate = true;
        // data.source = uri;
        console.warn(`The dataaaaaaaaaaaaa here is `, data)
        // self.audioUploader.source = URL.createObjectURL(data.source); //! Ensure blob is loaded so playback can happen
      },
      resetMediaState(){
        let self = this;
        self.posterUploader ={
          isPossible: true,
          source: null,
          loading: false,
          isUrl: false,
          isUpdate: true,
          progress: 0
        }

        self.audioUploader = {
          isPossible: true,
          source: null,
          loading: false,
          isUrl: false,
          isUpdate: true,
          progress: 0
        }
      },
      addNewTitle(){
        console.log('CREATING NEW LABEL....');
        let self = this;
        self.resetMediaState();
        self.showBufferOverlay({
          msg: `Loading title data...`,
        })
        
        self.titleData = {
          ...self.blankTitle,
          ...{
            titleId: new Date().getTime().toString(),
            released: self.toISODate(new Date())
          }
        }

        self.loadingEditor = false;
        self.itemFound = true;
        self.hideBufferOverlay();
      },
      remapData(data, hasAudio){
        let self = this;
        
        let newData = {
          titleId: data.titleId,
          images: data.images
        };
        
        if(!data.fingerprinting_progress){
          if(data.type === 'movie') newData.fingerprinting_progress = hasAudio? 'required' : 'notRequired' 
          if(data.type === 'series') newData.fingerprinting_progress = 'notRequired'
          if(data.type === 'episode') newData.fingerprinting_progress = hasAudio? 'required' : 'notRequired' 
          if(data.type === 'book') newData.fingerprinting_progress = 'notRequired'
          if(data.type === 'chapter') newData.fingerprinting_progress = hasAudio? 'required' : 'notRequired' 
        }else{
          newData.fingerprinting_progress = data.fingerprinting_progress
        }

        Object.keys({...data}).forEach(prop=>{
          if([...self.typeUiCfg.inputCfg].map(o=>o.input).includes(prop)){
            newData[prop] = data[prop];

            if(prop === 'chapter' || prop === 'episode'){
              newData.parentId = data.parentId;
            }
          }
        });

        console.log('remap::', data, newData, [...self.typeUiCfg.inputCfg]);
        return newData;

      },
      async editItem(item){
        console.log('editItem', item);
        let self = this;
        self.resetMediaState();
        self.loadingEditor = true;
        self.showBufferOverlay({
          msg: `Loading title data...`,
        })

        self.titleData = {
          ...item,
          ...{
            released: self.toISODate(item.released),
            images: item.images || [],
          }
        }
        
        delete self.titleData.createdAt;
        delete self.titleData.modifiedAt;
        if(self.titleData.images && self.titleData.images[0]){
          let posterExists = await self.testUrlAvailability(`https://shazacin-static-content.s3-eu-west-1.amazonaws.com/${self.titleData.images[0]}`);
          console.log('posterExists', posterExists, `https://shazacin-static-content.s3-eu-west-1.amazonaws.com/${self.titleData.images[0]}`, self.titleData.images);
          if(posterExists){
            self.posterUploader.source = `https://shazacin-static-content.s3-eu-west-1.amazonaws.com/${self.titleData.images[0]}`;
            self.posterUploader.isUrl = true;
            self.posterUploader.isUpdate = false;
          }
        }

        // let audioLink = self.getS3Link(`/audio-tracks-to-process/${self.titleData.titleId}.wav`);
        if(self.titleData.fingerprinting_progress === 'completed'){
          let audioSignedUrl = await self.$Amplify.Storage.get(`audio-tracks-processed/${self.titleData.titleId}.mp3`, {
            expires: 604800,
            bucket: 'shazacin-t2s',
            region: 'eu-west-1',
            customPrefix: {
              public: ''
            },
            level: "public"
          })

          let audioExists = await self.testUrlAvailability(audioSignedUrl);
          console.log('audioLink audioSignedUrl', audioSignedUrl, audioExists);

          if(audioExists){
            self.audioUploader.source = audioSignedUrl;
            self.audioUploader.isUrl = true;
            self.audioUploader.isUpdate = false;
          }
        }
        
        
        self.originalTitleData = {...self.titleData};

        self.loadingEditor = false;
        self.itemFound = true;
        self.hideBufferOverlay();
      },
      async selectSearchedTitle(title){
        
        let self = this;

        if(title){
          self.titleData = {
            actors: title.Actors.split(', '),
            categories: title.Genre.split(', '),
            directors: title.Director.split(', '),
            genre: title.Genre.split(', '),
            rated: title.Rated,
            released: self.toISODate(title.Released),
            runtimeMinutes: parseInt(title.Runtime.replace('min', '')),
            score: title.imdbRating,
            synopsis: title.Plot,
            title: title.Title,
            writers: title.Writer.split(', '),
            year: title.Year,
            //NO CHANGE BELOW
            titleId: self.titleData.titleId,
            publicEnabled: self.titleData.publicEnabled,
            type: self.titleData.type,
            parentId: self.titleData.parentId,
          } 

          let posterBlob = await self.getBlobFromUrl(title.Poster);
          self.posterUploader.source = posterBlob;
          self.blobToBase64(self.posterUploader, true);
        }
      },
      setCategories(categories){
        console.log('setCategories E', categories);
        this.titleData.categories = [...new Set( categories.map(o=> o.toLowerCase()) )]
      },
      setGenre(genre){
        console.log('setCategories E', genre);
        this.titleData.genre = [...new Set( genre.map(o=> o.toLowerCase()) )]
      },
      setTitleYear(released){
        this.titleData.year =parseInt(new Date(released).getFullYear());
      },
      removeChip(item, dataSet){
        console.log(' removeChip \nITEM:', item, '\nFrom: ', dataSet, this.titleData[dataSet]);
        this.titleData[dataSet] = this.titleData[dataSet].filter(o=> o !== item);
      },
      uploadaudio(audio, path){
        ///audio-tracks-to-process/
        console.log('uploadaudio UPLOAD audio ', audio, path);
        let self = this;

        if(!audio) {
          console.error('uploadaudio ERR :: NO AUDIO PROVDED');
          return false;
        }

        return new Promise((resolve, reject)=>{
          self.$Amplify.Storage.put(path, audio ,{
            customPrefix: {
              public: '',
            },
            contentType: audio.type,
            bucket: 'shazacin-t2s',
            region: 'eu-west-1',
            expires: new Date(new Date().getTime() + 60 * 60 * 24 * 7),
            level: "public",
            progressCallback(progress){
              console.log(`Uploading track...: ${progress.loaded}/${progress.total}`);
              self.audioUploader.progress = self.getProgressPercentage(progress.total, progress.loaded)
            }
          })
            .then (result => { console.log('UPLOAD TRACK DONE', result); resolve({status: 1, result}) })
            .catch(error => { console.log('UPLOAD TRACK ERR',error); resolve({status: 0, error: error.message}) });
        });
      },
      async saveTitle(){
        let self = this;
        self.savingTitle = true;
        self.$refs.editorForm.validate();

        if(!self.titleValid){
          return false;
        }
        let methodCfg = self.editorState === 'create'? {operationKey: 'createShazacinMetadataTitles', operation: createShazacinMetadataTitles, methodType: 'create'} :  {operationKey: 'updateShazacinMetadataTitles', operation: updateShazacinMetadataTitles, methodType: 'update'} 
        console.warn(`BEFORE confirm save TITLE DATA:::: `, self.titleData)

        self.confirm({
          heading: 'Warning',
          msg: `You are about to ${methodCfg.methodType} <b>${self.titleData.title}</b>, do you wish to continue?`,
          type:'warning',
          actions: [
            {
              color: 'warn',
              isFlat: true,
              text: 'cancel',
            },
            {
              color: 'success',
              isFlat: false,
              text: methodCfg.methodType,
              method: async () => {
                self.showBufferOverlay({
                  msg: `${methodCfg.methodType === 'update'? 'updating' : 'creating' } ${self.titleData.type}...`,
                })

                self.alert({
                  timeout: 10000,
                  msg: self.$t(`notifs.titleEditor.${methodCfg.methodType}.busy`, {title: self.titleData.title})
                })

                console.log('POSTER AND AUDIO', self.audioUploader, self.posterUploader);

                let res = false;
                let folder = new Date().getTime();
                let posterBlob = null;
                let posterExtensionCfg = null;
                let posterFullPath = null;

                if(!!self.posterUploader.isUpdate && !!self.posterUploader.source){
                  posterBlob = await self.getBlobFromUrl(self.posterUploader.source);
                  posterExtensionCfg = self.uiDataSets.mimeTypeExtensionMapping.images.find(o=> o.mimeType === posterBlob.type);
                  posterFullPath = `simg/${folder}/${self.titleData.title.replace(/\s/g, '_')}_${folder}${posterExtensionCfg.extension}`;
                }
                
                //SAVE TITLE DATA --------------------------------------------------------
                try{
                  console.warn(`BEFORE REMAP TITLE DATA:::: `, self.titleData)
                  let input = self.remapData({
                    ...self.titleData,
                    ...{
                      images: !!self.posterUploader.isUpdate? [posterFullPath] : self.titleData.images,
                      publicEnabled: self.titleData.publicEnabled? 1: 0,
                      categories: self.titleData.categories.map(o=> o.toLowerCase()),
                      previewUrl: !!self.titleData.previewUrl? self.titleData.previewUrl : null,
                    }
                  }, !!self.audioUploader.source);
                  
                  console.warn(`AFTERRRRRRRRRRRR REMAP TITLE DATA:::: `, input)
                  if(self.editorState === 'create'){
                    delete input.titleId;
                  }else{
                    delete input.updatedAt;
                  }

                  console.log('remap:: saveTitle INPUT DATA', input);
                  let mutationRes = await self.$Amplify.API.graphql(
                    self.$Amplify.graphqlOperation(methodCfg.operation, 
                    { 
                      input,
                    })
                  )

                  res = {status: 1, data: mutationRes.data[methodCfg.operationKey]}
                }catch(e){
                  console.error(`${methodCfg.operationKey} ERROR::`, e )
                  res = {status: 0, errors: e.errors}
                }
                console.log('RES:: TITLE DATA', res, self.posterUploader, self.audioUploader);
                
                if(res.status){
                  let audioRes = false, posterRes = false;
                  //SAVE POSTER--------------------------------------
                  if(!!self.posterUploader.source && self.posterUploader.isUpdate && ([...self.typeUiCfg.inputCfg].map(o=>o.input).includes('poster'))){
                     self.alert({
                      timeout: 20000,
                      msg: self.$t(`notifs.titleEditor.uploadPoster.busy`, {title: self.titleData.title}),
                    })
                    
                    self.posterUploader.loading = true;
                    posterRes = await self.uploadPoster(posterBlob, posterFullPath);
                    console.log('RES:: POSTER', posterRes);
                    self.posterUploader.loading = false;

                    if(posterRes.status){
                      self.alert({
                        timeout: 5000,
                        msg: self.$t(`notifs.titleEditor.uploadPoster.success`, {title: self.titleData.title}),
                        color: 'success',
                        icon: 'mdi-check-circle-outline'
                      })

                    }else{
                      self.alert({
                        timeout: 5000,
                        msg: self.$t(`notifs.titleEditor.uploadPoster.error`, {title: self.titleData.title, err: posterRes.error}),
                        color: 'error',
                        icon: 'mdi-check-circle-outline'
                      })
                      return
                    }
                  }

                  //SAVE AUDIO -----------------------------------------
                  console.log('self.audioUploader.source',self.audioUploader.source, !!self.audioUploader.source)
                  if(
                      (!!self.audioUploader.source)
                      &&
                      (!!self.audioUploader.isUpdate)
                      &&
                      ([...self.typeUiCfg.inputCfg].map(o=>o.input).includes('audio'))
                    ){
                    self.alert({
                      timeout: 20000,
                      msg: self.$t(`notifs.titleEditor.uploadAudio.busy`, {title: self.titleData.title}),
                    })

                    let audioBlob = await self.getBlobFromUrl(self.audioUploader.source);
                    let audioExtensionCfg = self.uiDataSets.mimeTypeExtensionMapping.audio.find(o=> o.mimeType === audioBlob.type);
                    let audioFullPath = `audio-tracks-to-process/${res.data.titleId}${audioExtensionCfg.extension}`;
                    self.audioUploader.loading = true;
              
                    audioRes = await self.uploadaudio(audioBlob, audioFullPath);
                    console.log('RES:: AUDIO', audioRes);
                    self.audioUploader.uploading = false;

                    if(audioRes.status){
                      self.alert({
                        timeout: 5000,
                        msg: self.$t(`notifs.titleEditor.uploadAudio.success`, {title: self.titleData.title}),
                        color: 'success',
                        icon: 'mdi-check-circle-outline'
                      })
                    }else{
                      self.alert({
                        timeout: 5000,
                        msg: self.$t(`notifs.titleEditor.uploadAudio.error`, {title: self.titleData.title, err: audioRes.error}),
                        color: 'error',
                        icon: 'mdi-check-circle-outline'
                      })
                      return;
                    }
                  }

                  if(!self.posterUploader.isUpdate && !self.audioUploader.isUpdate){
                    self.alert({
                      timeout: 5000,
                      msg: self.$t(`notifs.titleEditor.${methodCfg.methodType}.success`, {title: self.titleData.title}),
                      color: 'success',
                      icon: 'mdi-check-circle-outline'
                    })
                  }

                  if(posterRes.status && audioRes.status){
                    self.alert({
                      timeout: 5000,
                      msg: self.$t(`notifs.titleEditor.${methodCfg.methodType}.success`, {title: self.titleData.title}),
                      color: 'success',
                      icon: 'mdi-check-circle-outline'
                    })
                  }
                  
                  self.reloadEditor('update', res);
    
                }else{
                  self.alert({
                    timeout: 5000,
                    msg: self.$t(`notifs.titleEditor.${methodCfg.methodType}.error`, {title: self.titleData.title, err: res.errors.map(o=> o.message).join(', ')}),
                    color: 'error',
                    icon: 'alert-circle-outline'
                  })
                }

                self.savingTitle = false;
                // self.hideBufferOverlay();
                
              }
            }
          ]
        })
      },
      async deleteTitle(){
        let self = this;
        console.log('DELETE ', self.titleData.titleId);
        self.confirm({
          heading: 'Warning',
          msg: `You are about to delete <b>${self.titleData.title}</b>, do you wish to continue?`,
          type:'warning',
          actions: [
            {
              color: 'warn',
              isFlat: true,
              text: 'cancel'
            },
            {
              color: 'error',
              isFlat: false,
              text: 'delete',
              method: async () => {
                self.alert({
                  timeout: 5000,
                  msg: self.$t('notifs.titleEditor.delete.busy', {title: self.titleData.title})
                })

                await self.$Amplify.API.graphql(
                  self.$Amplify.graphqlOperation(deleteShazacinMetadataTitles, 
                  {
                    input: {
                      titleId: self.titleData.titleId || 'delete'
                    } 
                  })
                ).then(res => {
                  self.alert({
                    timeout: 5000,
                    msg: self.$t('notifs.titleEditor.delete.success', {title: self.titleData.title}),
                    color: 'success',
                    icon: 'mdi-check-circle-outline'
                  })
                  console.log('DELETED TITLE');
                  
                  self.$router.replace({path: '/titleManagement'});
                })
                .catch(res=> {
                  self.alert({
                    timeout: 5000,
                    msg: self.$t('notifs.titleEditor.delete.error', {title: self.titleData.title, err: res.errors.map(o=> o.message).join(', ')}),
                    color: 'error',
                    icon: 'alert-circle-outline'
                  })
                })
              }
            }
          ]
        })
      },
      uploadPoster(poster, path){
        console.log('uploadPoster UPLOAD POSTER', poster, path);
        let self = this;

        if(!poster) {
          console.error('uploadPoster ERR :: NO POSTER PROVDED');
          return false;
        }

        return new Promise((resolve, reject)=>{
          self.$Amplify.Storage.put(path, poster ,{
            customPrefix: {
              public: ''
            },
            contentType: poster.type,
            bucket: 'shazacin-static-content',
            region: 'eu-west-1',
            expires: new Date(new Date().getTime() + 60 * 60 * 24 * 7),
            level: "public",
            progressCallback(progress){
              console.log(`Uploading...: ${progress.loaded}/${progress.total}`);
              self.posterUploader.progress = self.getProgressPercentage(progress.total, progress.loaded)
            }
          })
            .then (result => { console.log('UPLOAD DONE', result); resolve({status: 1, result}) })
            .catch(error => { console.log('UPLOAD ERR',err); resolve({status: 0, error: error.message}) });
        });
      }
    },
    async mounted(){
      let self = this;
      self.itemFound = false;
      self.loadingEditor = true
      console.log('TRACKUPLOADER....', this.$route.params);
      self.titleId = this.$route.params.titleId;
      self.editorState = this.$route.params.editorState;
      self.showBufferOverlay({
        msg: `Loading title editor...`,
      })
      self.titles = await self.getAllTitles();
      
      if(self.editorState === 'create'){
        self.addNewTitle()
      }
      else{
        //do fetch instead... 
        let item = await self.getTitle(self.titleId);
        if(item){
          self.editItem(item);
        }else{
          self.loadingEditor = false
        }
      }
    },
    watch: {
      releaseDateMenu (val) {
        val && setTimeout(() => (this.$refs.releaseDatePicker.activePicker = 'YEAR'))
      },
    },
  }
</script>


