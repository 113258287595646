<template>
  <v-card flat class="ma-2 mt-4 transparent">
    <v-card-text class="ma-0 pa-0">
      <v-row>
        <v-col v-for="(track, i) in adTrackData" :key="`ad_track_${i}_${track.trackId}`" cols="12" sm="6" md="4" lg="3">
          <v-card class="ma-2 pa-2 elevation-1" :min-width="260">
            <v-card-title>{{lang.trackCard.heading}} {{i+1}}</v-card-title>
            <v-form v-model="track.uiCfg.valid" ref="trackForm">
              <v-card-text>
                <v-row justify="end" align="center" no-gutters>
                  <span>{{inputLang.TitlePublicEnabled.label}}</span>
                  <v-btn-toggle dense id="publicEnabledToggle" v-model="track.uiCfg.publicEnabled" @change="()=>{ track.publicEnabled = (track.uiCfg.publicEnabled === true) }" mandatory :label="inputLang.TitlePublicEnabled.label" :color="track.uiCfg.publicEnabled?'green':'red'" :aria-label="publicEnabled?'Description track Publicly enabled, toggle to disable.':'Description track Disabled, toggle to enable publicly.'"> 
                    <v-btn :value="false"  aria-label="Disable description track"> 
                      <v-icon :color="track.uiCfg.publicEnabled?'':'error'">close</v-icon> 
                    </v-btn> 
                    <v-btn :value="true"  aria-label="Enable description track">
                      <v-icon :color="track.uiCfg.publicEnabled?'green':''">check</v-icon> 
                    </v-btn> 
                  </v-btn-toggle>
                </v-row>
                <v-row justify="center" align="center">
                  <v-text-field
                  v-model="track.name"
                  :rules="validators.trackName(true)"
                  :label="inputLang.trackName.label"
                />
                </v-row>
                <v-row justify="center" align="center">
                  <v-text-field
                  placeholder="default"
                  type="number"
                  v-model="track.trackPosition"
                  :label="inputLang.trackPosition.label"
                />
                </v-row>
                <v-row justify="center" align="center">
                  <v-text-field 
                  v-model="track.narrator"
                  :rules="validators.trackNarrator(true)"
                  :label="inputLang.trackNarrator.label"
                />
                </v-row>
                <v-row justify="center" align="center">
                  <v-autocomplete
                    v-model="track.narratedLanguage"
                    :items="uiDataSets.languages"
                    :rules="validators.trackNarratedLang(true)"
                    :label="inputLang.trackNarratedLang.label"
                    item-value="code"
                    item-text="name"
                  ></v-autocomplete>
                </v-row>
                <v-row no-gutters class="mt-4 audio_upload" align="center" justify="center" >
                  <v-card v-if="track.uiCfg.audioUploader.isUrl" flat class="transparent mb-5" style="width:100%">
                    <v-card-actions class="pa-0 ma-0">
                        <v-btn icon text color="error" class="pa-0 ma-0"
                        aria-label="Remove description track audio"
                          style="position:absolute; top:-20px; right:-20px;"
                          @click="track.uiCfg.audioUploader.source = null; track.uiCfg.audioUploader.isUrl = false; track.uiCfg.audioUploader.isUpdate = true; "
                        >
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </v-card-actions>
                    <v-card-text class="pa-0 ma-0 ">
                      <audio controls v-if="track.uiCfg.audioUploader.isUrl" style="width:100%; margin:auto;">
                        <source :src="track.uiCfg.audioUploader.source">
                      </audio>
                      <v-progress-linear
                        v-show="track.uiCfg.audioUploader.loading"
                        background-color="shaza_light_blue"
                        color="success"
                        :value="track.uiCfg.audioUploader.progress"
                        height="12"
                      >
                        <span class="caption white--text">{{track.uiCfg.audioUploader.progress}}%</span>
                      </v-progress-linear>
                    </v-card-text>
                  </v-card>

                  <v-card v-else flat class="transparent audio_upload" style="width:100%">
                    <v-card-actions class="pa-0 ma-0">
                        <v-menu open-on-hover offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="shaza_light_blue" dark v-bind="attrs" v-on="on" style="position:absolute; top:-20px; left:-20px;">mdi-information</v-icon>
                          </template>
                          <v-card color="shaza_dark_blue" dark>
                            <v-card-text>
                              Please use an (.mp3) file,
                            </v-card-text>
                          </v-card>
                        </v-menu>
                    </v-card-actions>
                    <v-card-text class="pa-0 ma-0 ">
                        <v-file-input
                          :rules="validators.trackAudio(true, uiDataSets.mimeTypeExtensionMapping.audio.map(o=> o.mimeType) )"
                          :accept="uiDataSets.mimeTypeExtensionMapping.audio.map(o=> o.extension).join(', ')"
                          placeholder="Upload Track Audio"
                          prepend-inner-icon="mdi-music-note-plus"
                          append-icon="mdi-music-note-plus"
                          prepend-icon=""
                          :clearable="false"
                          filled
                          height="40"
                          color="shaza_yellow"
                          outlined
                          flat
                          v-model="track.uiCfg.audioUploader.source"
                          @change="blobToBase64(track.uiCfg.audioUploader)"
                        />
                    </v-card-text>
                  </v-card>
                </v-row>
                <v-row justify="center" align="center">
                  <label class="block_label" :for="`releaseDate_${i}`" >Release Date</label>
                  <v-menu
                    :key="`releaseDate_picker_${i}_${track.trackId}`"
                    :ref="`releaseDate_picker_${i}_${track.trackId}`"
                    v-model="track.uiCfg.menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :id="`releaseDate_${i}`"
                        color="shaza_yellow"
                        dark
                        block
                        class="elevation-0"
                        @click="track.uiCfg.menu = true"
                        v-bind="attrs"
                        v-on="on"
                      ><v-icon class="mr-5">mdi-calendar</v-icon>{{track.releaseDate || 'Release Date' }}</v-btn>
                    </template>
                    <v-date-picker
                      color="shaza_yellow"
                      no-title
                      reactive
                      scrollable
                      v-model="track.releaseDate"
                      :rules="validators.trackReleaseDate(true)"
                      :label="inputLang.trackReleaseDate.label"
                      @input="track.uiCfg.menu = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-input :rules="validators.trackReleaseDate(true)" v-model="track.releaseDate" />
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field v-model="track.magic_adjust" :label="inputLang.magic_adjust.label" :placeholder="inputLang.magic_adjust.label" type="number" />
                  </v-col>    
                </v-row>
              </v-card-text>
              </v-form>
            <v-card-actions>
              <v-spacer />
              <!-- <v-btn text icon color="error" @click="deleteTrack(track)"><v-icon>mdi-delete-outline</v-icon></v-btn> -->
              <v-btn :disabled="savingTrack" small text color="error" class="elevation-0" @click="deleteTrack(track)">{{buttonLang.deleteTitle.label}}</v-btn>
              <v-btn :disabled="!track.uiCfg.valid || savingTrack" small color="shaza_yellow" class="elevation-0 white--text" @click="saveTrack(track, i)">{{track.uiCfg.type === 'new' ? buttonLang.saveAdTracks.label : buttonLang.updateAdTracks.label }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" v-if="!unsavedTracks">
          <v-card :disabled="unsavedTracks" class="ma-2 pa-2" :min-width="260" color="grey lighten-2" fill-height hover @click="addTrack">
            <div class="add_card_wrapper">
              <v-icon x-large >mdi-plus-circle-outline</v-icon>
              <span>add track </span>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { listShazacinMetadataAdTracks } from '@/graphql/queries'
import { 
  updateShazacinMetadataAdTracks,
  createShazacinMetadataAdTracks,
  deleteShazacinMetadataAdTracks, 
} from '@/graphql/mutations'

  export default {
    props: ['titleId'],
    data(){
      return {
        savingTrack: false,
        originalTrackData: [],
        adTrackData: [],
        blankTrack:{
          trackId: null,
          fileBlob: '',
          trackName: '',
          language: '',
          narrator: '',
          magic_adjust: 0,
          releaseDate: ''
        }
      }
    },
    computed:{
      unsavedTracks(){
        return this.originalTrackData.length !== this.adTrackData.length;
      },
      lang(){
        return this.$t('pages.TrackUploader');
      }
    },
    methods: {
      getBlobFromUrl(imageUrl){
        return new Promise((resolve, reject)=>{
          fetch(imageUrl)
          .then(async res =>{ 
            let blob = await res.blob(); 
            resolve(blob);  
          })
          .catch(e=>{ console.error(e); reject(e) })
        })
      },
      async blobToDataURI(blob) {
        return new Promise((resolve)=> {
          let a = new FileReader();
          a.onload = () => { resolve(a.result) }
          if(blob){
            a.readAsDataURL(blob);
          }
        });
      },
      async blobToBase64(data){
        let self = this;
        let uri = await self.blobToDataURI(data.source);
        data.isUrl = true;
        data.isUpdate = true;
        data.source = uri;
      },
      getADtracks(){
        let self = this; 
        return new Promise(async(resolve)=>{
          let res;
          try{

            let variables = {
              filter: {
                titleId: {eq: self.titleId}
              }
            }

            const query = await self.$Amplify.API.graphql({ query: listShazacinMetadataAdTracks, variables })
            let items = query.data.listShazacinMetadataAdTracks.items
            items = items.sort((a,b)=>{
              let rt = 0
              if ((typeof b.trackPosition == 'undefined') || (b.trackPosition == null)) rt = -1
              else if ((typeof a.trackPosition == 'undefined') || (a.trackPosition == null)) rt = 1
              else if (a.trackPosition > b.trackPosition) rt = 1
              else if (a.trackPosition < b.trackPosition) rt = -1
              else rt = 0
              return rt 
            })
            console.log('getAdtracks query', items);
            res = {status: 1, data: items}
          }catch(e){
            console.error(`listShazacinMetadataAdTracks ERROR::`, e )
            res = {status: 0, errors: e.errors}
          }
          resolve(res)
        });
      },
      addTrack(){
        let self = this;
        self.adTrackData.push({
          ...self.blankTrack, 
          ...{
            titleId: self.titleId, 
            trackId: new Date().getTime(), 
            uiCfg: { 
              menu: false, type: 'new', valid: false, publicEnabled: false,
              audioUploader:{
                source: null,
                isUrl: false,
                isUpdate: true,
                loading: false,
                progress: 0
              },
            }
          }
        })
      },
      async saveTrack(track, trackIndex){
        let self = this;

        if(!track.uiCfg.valid){
          return false;
        }
        self.savingTrack = true;

        let methodCfg = track.uiCfg.type === 'new'? {operationKey: 'createShazacinMetadataAdTracks', operation: createShazacinMetadataAdTracks, methodType: 'create'} :  {operationKey: 'updateShazacinMetadataAdTracks', operation: updateShazacinMetadataAdTracks, methodType: 'update'}

        self.showBufferOverlay({
          msg: `${methodCfg.methodType === 'create'? 'creating' : 'saving'} track...`,
        })

        self.alert({
          timeout: 10000,
          msg: self.$t(`notifs.trackEditor.${methodCfg.methodType}.busy`, {track: track.name})
        })

        let res = false;
        //SAVE TRACK DATA --------------------------------------------------------
        try{
          let input = {
            ...{
              trackId: track.trackId,
              name: track.name,
              narratedLanguage: track.narratedLanguage,
              narrator: track.narrator,
              titleId: track.titleId,
              publicEnabled: track.publicEnabled? 1: 0,
              trackPosition: track.trackPosition || null,
              releaseDate: new Date(track.releaseDate).getTime(),
              trackUrl: 'No configured yet.'
            }
          }
          if (track.magic_adjust && track.magic_adjust.length > 0) {
            input.magic_adjust = track.magic_adjust
          }
          else {
            input.magic_adjust = null
          }

          if(track.uiCfg.type === 'new'){
            delete input.trackId
          }

          console.log('saveTrack input', input);

          let mutationRes = await self.$Amplify.API.graphql(
            self.$Amplify.graphqlOperation(methodCfg.operation, { input })
          )

          res = {status: 1, data: mutationRes.data[methodCfg.operationKey]}
        }catch(e){
          console.error(`saveTrack ${methodCfg.operationKey} ERROR::`, e )
          res = {status: 0, errors: e.errors}
        }

        if(res.status){
          //SAVE AUDIO -----------------------------------------
          self.alert({
            timeout: 20000,
            msg: self.$t(`notifs.trackEditor.uploadAudio.busy`, {track: track.name}),
          })

          if(track.uiCfg.audioUploader.isUpdate){
            let audioBlob = await self.getBlobFromUrl( track.uiCfg.audioUploader.source);
            let audioExtensionCfg = self.uiDataSets.mimeTypeExtensionMapping.adTrackAudio.find(o=> o.mimeType === audioBlob.type);
            let audioFullPath = `audio-description-tracks-to-process/adtracks/${res.data.titleId}/${res.data.trackId}${audioExtensionCfg.extension}`;
            track.uiCfg.audioUploader.loading = true;
      
            let audioRes = await self.uploadTrackAudio(audioBlob, audioFullPath, track.uiCfg);
            console.log('AUDIO RES', audioRes);
            track.uiCfg.audioUploader.loading = false;
            track.uiCfg.valid = true;
            if(audioRes.status){
              self.alert({
                timeout: 5000,
                msg: self.$t(`notifs.trackEditor.${methodCfg.methodType}.success`, {track: track.name}),
                color: 'success',
                icon: 'mdi-check-circle-outline'
              })
            }else{
              self.alert({
                timeout: 5000,
                msg: self.$t(`notifs.trackEditor.uploadAudio.error`, {track: track.name, err: audioRes.error}),
                color: 'error',
                icon: 'mdi-check-circle-outline'
              })
              return;
            }
          }
          
          self.alert({
            timeout: 5000,
            msg: self.$t(`notifs.trackEditor.${methodCfg.methodType}.success`, {track: track.name}),
            color: 'success',
            icon: 'mdi-check-circle-outline'
          })
          await  self.pause(2000);
          self.buildAdTrackData();

        }else{
          self.alert({
            timeout: 5000,
            msg: self.$t(`notifs.trackEditor.${methodCfg.methodType}.error`, {track: track.name, err: res.errors.map(o=> o.message).join(', ')}),
            color: 'error',
            icon: 'alert-circle-outline'
          })
        }
       
        self.savingTrack = false;
        self.hideBufferOverlay()
        
      },
      async deleteTrack(track){
        let self = this;
        self.savingTrack = true;
        if(self.tracks)
        self.showBufferOverlay({
          msg: self.$t('notifs.trackEditor.delete.busy', {track: track.name})
        })

        self.alert({
          timeout: 5000,
          msg: self.$t('notifs.trackEditor.delete.busy', {track: track.name})
        })

        // self.titles = self.titles.filter(o=> o.id !== self.titleData.id);

        await self.$Amplify.API.graphql(
          self.$Amplify.graphqlOperation(deleteShazacinMetadataAdTracks, 
          {
            input: {
              trackId: track.trackId
            } 
          })
        ).then(res => {
          self.alert({
            timeout: 5000,
            msg: self.$t('notifs.trackEditor.delete.success', {track: track.name}),
            color: 'success',
            icon: 'mdi-check-circle-outline'
          })
          self.buildAdTrackData();
          
        })
        .catch(res=> {
          self.alert({
            timeout: 5000,
            msg: self.$t('notifs.trackEditor.delete.error', {track: track.name, err: res.errors.map(o=> o.message).join(', ')}),
            color: 'error',
            icon: 'alert-circle-outline'
          })
          
        })
        self.savingTrack = false;
        self.hideBufferOverlay()
      },
      getTrackUrl(titleId, trackId){
        let self = this;
        return new Promise(async (resolve) => {
          let Url = `https://shazacin-static-content.s3-eu-west-1.amazonaws.com/adtracks/${titleId}/${trackId}.mp3`
          let audioExists = await self.testUrlAvailability(Url);
          if(audioExists) resolve(Url)
          else resolve(null)
        });
        
      },
      async buildAdTrackData(){
        let self = this;
        console.log('titleId', self.titleId)
        if(!self.titleId) return;
        
        self.showBufferOverlay({
          msg: `Loading Track Data...`,
        })

        let adTrackData = await self.getADtracks();
        console.log('adTrackData', adTrackData);
        if(adTrackData.status){
          self.adTrackData = await Promise.all([...adTrackData.data].map(async (o)=>{
            let url = await self.getTrackUrl(o.titleId, o.trackId);
            
            let audioUploader = {
              source: url,
              isUrl: url? true : false,
              isUpdate: url? false : true,
              loading: false,
              progress: 0
            };

            console.log('TACK URL', audioUploader);

            return {
              ...o, ...{ releaseDate: self.toISODate(new Date(o.releaseDate)) },
              uiCfg: {
                publicEnabled: o.publicEnabled? true:false,
                valid: true,
                menu: false,
                type: 'edit',
                audioUploader
              }
          }}))

          self.originalTrackData = [...self.adTrackData];
        }else{
          self.adTrackData = []
          self.originalTrackData = []
          //trwo error
        }
        self.hideBufferOverlay()
      },
      uploadTrackAudio(audio, path, trackCfg){
        ///audio-tracks-to-process/
        console.log('uploadTrackAudio UPLOAD POSTER', audio, path, trackCfg);
        let self = this;

        if(!audio) {
          console.error('uploadTrackAudio ERR :: NO AUDIO PROVDED');
          return false;
        }

        return new Promise((resolve, reject)=>{
          self.$Amplify.Storage.put(path, audio ,{
            customPrefix: {
              public: '',
            },
            contentType: audio.type,
            bucket: 'shazacin-t2s',
            region: 'eu-west-1',
            expires: new Date(new Date().getTime() + 60 * 60 * 24 * 7),
            level: "public",
            progressCallback(progress){
              console.log(`uploadTrackAudio Uploading track...: ${progress.loaded}/${progress.total}`);
              trackCfg.audioUploader.progress = self.getProgressPercentage(progress.total, progress.loaded)
            }
          })
            .then (result => { console.log('uploadTrackAudio UPLOAD TRACK DONE', result); resolve({status: 1, result}) })
            .catch(error => { console.log('uploadTrackAudio UPLOAD TRACK ERR',error); resolve({status: 0, error: error.message}) });
        });
      },
    },
    mounted(){
      console.log('titleId', this.titleId);
      this.buildAdTrackData();
    },
    watch:{
      titleId(v){
        console.log('ID UPDATED', v);
        if(v){ 
          this.buildAdTrackData();
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.add_card_wrapper{
  flex-direction: column;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  span{
    text-align: center;
    font-weight: bolder;
  }
}
</style>